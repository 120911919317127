import React from "react";
import "./NuestrasApps.css";


const appsWeb = [
  
  { 
    id: 2, 
    title: "Formularios Digitales", 
    description: "Desarrollo e implementación de soluciones integrales para la digitalización de formularios y la automatización de procesos de negocio. Estas soluciones están diseñadas para optimizar la recolección, gestión y análisis de datos, mientras garantizan el cumplimiento de cada paso en los flujos de trabajo establecidos.", 
    logos: [
      "portal44.jpg",
      
    ] 
  },
  { 
    id: 2, 
    title: "Permisos de Trabajo Digitales", 
    description: "El sistema de permisos de trabajo está diseñado para gestionar, organizar y registrar la emisión, aprobación, seguimiento y cierre de permisos para la realización de tareas operativas en ambientes laborales controlados. Este sistema asegura el cumplimiento de las normativas de seguridad y salud ocupacional, minimizando riesgos asociados a actividades específicas.", 
    logos: [
      "portal1.jpg",
      
    ] 
  },
  { 
    id: 2, 
    title: "Capacitación y evaluación para análisis de riesgos", 
    description: "Sistema de instrucción para tareas operativas, diseñado para su uso grupal o individual. Permite mostrar un video que refuerza las instrucciones y procedimientos relacionados con la tarea, seguido de un cuestionario que evalúa la comprensión de los peligros asociados y las barreras de seguridad a implementar. Al finalizar, se genera un informe con los resultados, asegurando que los participantes estén preparados para llevar a cabo la tarea de manera segura y eficiente", 
    logos: [
      "portal4.jpg",
    ] 
  },
];

const NuestrasApps = () => {
  return (
    <section id="apps" className="apps-section">
      <div className="container">
        <h2>Desarrollo de Software</h2>
        <div class="text-container">
        <div>
          En nuestros proyectos desarrollamos soluciones personalizadas adaptadas a las necesidades de nuestros clientes. Ya sea creando aplicaciones a medida según sus requerimientos específicos o evaluando sus procesos para proponer soluciones integrales que optimicen su productividad. Nuestro enfoque combina innovación, eficiencia y resultados concretos para garantizar su satisfacción.
        
          Para ello utilizamos tecnología Arquitectura Cliente-Servidor con APIs RESTful, dentro del enfoque general de aplicaciones modernas frontend/backend desacopladas. Este diseño permite flexibilidad y escalabilidad para aplicaciones web y móviles.
        
          Esta arquitectura no solo conecta aplicaciones frontend con backend, sino que también permite la interacción con una variedad de sistemas externos, desde dispositivos IoT hasta equipos industriales, ampliando las posibilidades de automatización y eficiencia.
        </div>
      </div>
      <h2> </h2>
      <h2>Nuestros Trabajos</h2>
      <div className="services-grid-app">
          {appsWeb.map(service => (
            <div key={service.id} className="service-card-app">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <div className="logos-container">
                {service.logos.map((logo, index) => (
                  <img 
                    key={index} 
                    src={`/assets/images/${logo}`} 
                    alt={`${logo.split("-")[0]} logo`} 
                    className="service-logo-app1" 
                  />
                ))}
              </div>
            </div>
          ))}
           
        </div>
      </div>
    </section>
  );
};
export default NuestrasApps;

