import React from "react";
import "./NosotrosSection.css";

const services = [
  { 
    id: 1, 
    title: "Matias Bonfanti", 
    description: "Departamento de software", 
    logos: [
      "perfil.jpg", 
      
    ] 
  },
  { 
    id: 2, 
    title: "Gonzalo Lenardon", 
    description: "Desarrollador", 
    logos: [
      "perfil.jpg",
      
    ] 
  },
  { 
    id: 3, 
    title: "Natalia Molina", 
    description: "Desarrolladora", 
    logos: [
      "perfil.jpg",
   
    ] 
  },
  { 
    id: 4, 
    title: "Francisco García", 
    description: "Desarrollador", 
    logos: [
      "perfil.jpg", 
      
    ] 
  }
];
const services2 = [
  { 
    id: 5, 
    title: "Nicolás Brocanelli", 
    description: "Control de calidad", 
    logos: [
      "perfil.jpg", 
    ] 
  },
  { 
    id: 6, 
    title: "María Laura", 
    description: "Diseño gráfico", 
    logos: [
      "perfil.jpg",
    
    ] 
  },
  
  { 
    id: 7, 
    title: "Gabriela Neme", 
    description: "Administración y finanzas", 
    logos: [
      "perfil.jpg",
    
    ] 
  },
 
];
const NosotrosSection = () => {
  return (
    <section id="about" className="about-section">
      <div className="container">
        <h2>Quienes somos? </h2>
        <div className="services-grid">
          {services.map(service => (
            <div key={service.id} className="service-card">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <div className="logos-container">
                {service.logos.map((logo, index) => (
                  <img 
                    key={index} 
                    src={`/assets/images/${logo}`} 
                    alt={`${logo.split("-")[0]} logo`} 
                    className="service-logo" 
                  />
                ))}
              </div>
            </div>
          ))}
           {services2.map(service => (
            <div key={service.id} className="service-card">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <div className="logos-container">
                {service.logos.map((logo, index) => (
                  <img 
                    key={index} 
                    src={`/assets/images/${logo}`} 
                    alt={`${logo.split("-")[0]} logo`} 
                    className="service-logo" 
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NosotrosSection;

