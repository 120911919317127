import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ServiceSection from "./components/ServiceSection";
import NosotrosSection from "./components/NosotrosSection";
import NuestrasApps from "./components/NuestrasApps";
import WhatsAppButton from "./components/WhatsAppButton";
import ContactoSection from "./components/Contacto";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <ServiceSection />    
        <NuestrasApps />
        <NosotrosSection />
        <ContactoSection />
      </main>
      <Footer />
      <WhatsAppButton />
    </div>
  );
};

export default App;
