import React from "react";
import "./ServiceSection.css";

const services = [
  { 
    id: 1, 
    title: "Desarrollo de Software", 
    description: "Transformamos ideas en soluciones tecnológicas innovadoras. Diseñamos y desarrollamos aplicaciones web, móviles y de escritorio que impulsan la productividad, optimizan procesos y generan resultados tangibles para tu negocio.", 
    logos: [
      /*
      "logo192.png", 
      "sql.jpg", 
      "android.jpg",
      "ios.jpg",
      */
    ] 
  },
  { 
    id: 2, 
    title: "Consultoría y Gestión de Microsoft 365 y Google Workspace", 
    description: "Facilitamos la transición de tu negocio hacia las plataformas digitales más avanzadas. Nuestra experiencia en migraciones y administración de Microsoft 365 y Google Workspace asegura una implementación rápida, segura y sin interrupciones. Desde la planificación estratégica hasta el soporte continuo, te ayudamos a optimizar el trabajo colaborativo, proteger tus datos y maximizar el retorno de inversión en tus herramientas digitales.", 
    logos: [
      /*
      "google.png", 
      "microsoft.webp"
      */
    ] 
  },
  { 
    id: 3, 
    title: "Asesoramiento y Venta de Equipamiento Informático", 
    description: "Equipamos a tu negocio con la tecnología adecuada para crecer. Te asesoramos en la selección de equipos informáticos que se ajusten a tus necesidades, combinando rendimiento, eficiencia y presupuesto. Desde estaciones de trabajo hasta servidores y soluciones de red, garantizamos productos de calidad respaldados por un servicio técnico confiable y personalizado.", 
    logos: [
      /*
      "hp-logo.png", 
      "dell-logo.png", 
      "acer-logo.png"
      */
    ] 
  },
];

const ServiceSection = () => {
  return (
    <section id="services" className="service-section">
      <div className="container">
        <h2>Nuestros Servicios </h2>
        <div className="services-grid">
          {services.map(service => (
            <div key={service.id} className="service-card">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
              <div className="logos-container">
                {service.logos.map((logo, index) => (
                  <img 
                    key={index} 
                    src={`/assets/logos/${logo}`} 
                    alt={`${logo.split("-")[0]} logo`} 
                    className="service-logo" 
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;

