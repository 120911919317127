import React from "react";
import "./Header.css";

const handleScrollToContact = () => {
  const contactSection = document.querySelector("#contact");
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: "smooth" });
  }
};

const Header = () => {
  return (
    <header className="header">
    <div className="header-container">
      <div className="logo">
        <a href="/">
          <img src="/assets/images/logo2.jpg" alt="Logo" className="logo-img" />
          <span className="logo-h">H</span><span className="logo-devs">DEVS</span> | DESARROLLOS INFORMÁTICOS
        </a>
      </div>
      <nav className="navigation">
        <ul>
          <li><a href="#services">Servicios</a></li>
          <li><a href="#about">Nosotros</a></li>
          <li><a href="#contact">Contacto</a></li>
          <li><a href="#apps">Aplicaciones</a></li>
        </ul>
      </nav>
      <div className="cta">
      <button onClick={handleScrollToContact}>Solicitar una demostración</button>
      </div>
    </div>
  </header>
  );
};

export default Header;
